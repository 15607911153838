import axios from '@/axios.js'

export default {
    getMaps: async (resortUuid) => {
        try {
            const response = await axios.editorapi.get(`resorts/v3/${resortUuid}/maps`)
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
    getMapTypes: async (resortUuid) => {
        try {
            const response = await axios.editorapi.get(`resorts/v3/${resortUuid}/maps/types`)
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
    createMap: async (resortUuid, payload) => {
        try {
            const headers = { headers: { 'Content-Type': 'multipart/form-data' } }
            const response = await axios.editorapi.post(`resorts/v3/${resortUuid}/maps`, payload, headers)
            return response.data
        } catch (error) {
            throw new Error(error)
        } 
    },
    saveMap: async (resortUuid, mapUuid, payload) => {
        try {
            const headers = { headers: { 'Content-Type': 'multipart/form-data' } }
            const response = await axios.editorapi.post(`resorts/v3/${resortUuid}/maps/${mapUuid}`, payload, headers)
            return response.data
        } catch (error) {
            throw new Error(error)
        } 
    },
    deleteMap: async (resortUuid, mapUuid) => {
        try {
            const response = await axios.editorapi.delete(`resorts/v3/${resortUuid}/maps/${mapUuid}`)
            return response.data
        } catch (error) {
            throw new Error(error)
        } 
    }
}