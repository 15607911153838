var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DatatablePageLayout", {
    scopedSlots: _vm._u([
      {
        key: "actionButtons",
        fn: function() {
          return [
            _c(
              "vs-button",
              {
                staticClass: "rounded-lg xs:w-full sm:w-auto",
                attrs: {
                  to: {
                    name: "add-map",
                    params: { map: {}, mapTypesCreated: _vm.mapTypesCreated }
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("resorts.maps.add")) + " ")]
            )
          ]
        },
        proxy: true
      },
      {
        key: "datatable",
        fn: function() {
          return [
            _c("Datatable", {
              attrs: {
                config: _vm.datatableConfig,
                elements: _vm.maps,
                stripe: true,
                draggable: false,
                sst: false,
                pagination: _vm.pagination
              },
              on: { action: _vm.action, filter: _vm.filter }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }